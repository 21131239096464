<ng-container *ngIf="coinAccounts; else addressView">
    <ng-container *ngIf="account || !addCoinSettings; else createAccountView">
        <mat-form-field>
            <img class="mat-select-logo" [src]="account.icon" *ngIf="account"/>
            <mat-select placeholder="{{accountTitle}}" [(ngModel)]="account" (ngModelChange)="onAccountChange($event)" [ngClass]="{'no-account' : !account}">
                <mat-option *ngIf="!coinAccounts.length || allowEmptyAccount" [value]="false">Select Account</mat-option>

                <mat-option *ngFor="let account of coinAccounts" [value]="account" >
                    <div class="select-account-mat-option-text"><img class="select-account-mat-option-logo" [src]="account.icon"  /> {{account.name}}</div>
                </mat-option>
            </mat-select>
            <mat-hint align="end" *ngIf="accountHelpText">{{accountHelpText}}</mat-hint>
        </mat-form-field>
    </ng-container>

    <ng-template #createAccountView>
        <!-- Emulate form field styling -->
        <div class="mat-form-field my-16" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="mat-select-placeholder" >{{accountTitle}}</span>
            <button mat-raised-button color="accent" type="button" (click)="addCoin()">Create {{ coinSymbol }} Account</button>
        </div>
    </ng-template>
</ng-container>

<ng-template #addressView>
    <mat-form-field>
        <input matInput placeholder="{{addressTitle}}" [(ngModel)]="address" (ngModelChange)="onAddressChange($event)">
        <mat-hint align="end" *ngIf="addressHelpText">{{addressHelpText}}</mat-hint>
    </mat-form-field>
</ng-template>