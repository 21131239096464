import { Component } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import {FuseNavigation} from '../../../@fuse/types';
import {FuseSidebarService} from '../../../@fuse/components/sidebar/sidebar.service';

@Component({
    selector   : 'sample-memu',
    templateUrl: './sample-menu.component.html',
})
export class SampleMenuComponent
{
    nav: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        type     : 'group',
        children : [
            {
                id       : 'sample',
                title    : 'Sample',
                type     : 'item',
                icon     : 'email',
                url      : '/sample',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'sample-menu',
                title    : 'Sample Menu',
                type     : 'item',
                icon     : 'email',
                url      : '/sample-menu',
            }
        ]
    },
];


    constructor(private _fuseSidebarService: FuseSidebarService) {
    }

    toggleSidebar(): void
    {
        this._fuseSidebarService.getSidebar('sample-sidebar').toggleOpen();
    }
}

