import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {AppTransaction} from '../../coinomi-sdk/coinomi-sdk.service';


export class SendTransactionData {
    constructor(title: string, transaction: AppTransaction) {
        this.title = title;
        this.transaction = transaction;
    }

    title: string;
    transaction: AppTransaction;
}

@Component({
    selector: 'cnm-send-transaction',
    templateUrl: './send-transaction.component.html',
    styleUrls: ['./send-transaction.component.scss']
})
export class CnmSendTransactionComponent implements OnInit {
    public title: string;
    public transactionUri: string;
    public transaction: AppTransaction;


    constructor(private _bottomSheetRef: MatBottomSheetRef<CnmSendTransactionComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) data: SendTransactionData) {
        this.title = data.title || 'Send Transaction';
        this.transaction = data.transaction;
        this.transactionUri = this.transaction.createUri();
    }

    ngOnInit(): void {
    }


    cancel(): void {
        this._bottomSheetRef.dismiss(false);
    }

    ok(): void {
        this._bottomSheetRef.dismiss(true);
    }
}
