<div fxLayout="column" fxLayoutAlign="center start" class="p-12">

    <div class="m-12">
        <h1>{{title}}</h1>
    </div>

    <div fxLayout="column" fxFlexFill fxLayoutAlign="center center" class="fuse-card">
        <qrcode  class="m-12 mb-0" [qrdata]="transactionUri" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        <p class="mb-12 mt-0">
            <span class="text-boxed"> {{transactionUri}}</span>
        </p>
    </div>


    <div>
        <dl>
            <dt class="text-purple">Send</dt>
            <dd>{{transaction.receiverAddress}}</dd>

            <dt class="text-purple">Amount</dt>
            <dd>{{transaction.sendValue ? transaction.sendValue : 'Max'}}</dd>
        </dl>
    </div>

    <div fxFlexFill fxLayout="row" fxLayoutGap="24px" class="mt-12">
        <button fxFlex="1 0" mat-button color="warn" (click)="cancel()">Cancel</button>
        <button fxFlex="1 0" mat-button mat-raised-button color="accent" (click)="ok()">OK</button>
    </div>

</div>