import {Inject, Injectable, OnInit} from '@angular/core';
import {LOCAL_STORAGE, StorageService, StorageTranscoders} from 'ngx-webstorage-service';
import {CoinomiSdkService} from '../coinomi-sdk/coinomi-sdk.service';
import {CoinomiModule} from './coinomi.module';
import {CnmTermsDialogComponent} from './terms-dialog/terms-dialog.component';
import {AppStorageLocal, AppStorageServiceLocal} from './app-storage.service';
import {MatDialog} from '@angular/material/dialog';
import { CnmDialogComponent } from './dialog/cnm-dialog.component';
import { title } from 'process';

@Injectable({
    providedIn: 'root',
})
export class AppTermsService{
    private termsStorage: AppStorageLocal;

    constructor(private appStorageService: AppStorageServiceLocal, private dialog: MatDialog) {
        this.termsStorage = this.appStorageService.getStorage('AppTerms');
    }

    showTermsIfNeeded(key: string, serviveName: string): Promise<boolean>{
        return new Promise((resolve, reject) => {
            if (this.termsStorage.get(key)){
                resolve(true);
            }else{
                const dialogRef = this.dialog.open(CnmTermsDialogComponent, {
                    maxWidth: 550,
                    width: '90vw',
                    disableClose: true,
                    data : {key: key, serviceName : serviveName},
                });

                dialogRef.afterClosed().subscribe(action => {
                    if (action){
                        this.termsStorage.set(key, true);
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                });
            }
        });
    }


    showSepaDialogIfNeeded(key: string, serviceName: string): Promise<boolean>{
        return new Promise((resolve, reject) => {
            if (this.termsStorage.get(key)){
                resolve(true);
            }else{
                const dialogRef = this.dialog.open(CnmDialogComponent, {
                    maxWidth: 550,
                    width: '90vw',
                    disableClose: true,
                    data : {
                        title: 'BTC Direct',
                        message: 'This service is available only in European (SEPA) countries.',
                        options: { disableCancel: true }
                    },
                });

                dialogRef.afterClosed().subscribe(action => {
                    if (action){
                        this.termsStorage.set(key, true);
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                });
            }
        });
    }
}
