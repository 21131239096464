import {Inject, Injectable, OnInit} from '@angular/core';
import {LOCAL_STORAGE, SESSION_STORAGE, StorageService, StorageTranscoders} from 'ngx-webstorage-service';

@Injectable({
    providedIn: 'root',
})
export class AppStorageServiceLocal{
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

    has(key: string): boolean{
        return this.storage.has(key);
    }

    set(key: string, value: any): void{
        this.storage.set(key, value);
    }

    get(key: string): any{
        return this.storage.get(key);
    }

    remove(key: string): void{
        this.storage.remove(key);
    }

    getStorage(namespace: string): AppStorageLocal{
        return new AppStorageLocal(this, namespace);
    }
}

@Injectable({
    providedIn: 'root',
})
export class AppStorageServiceSession{
    constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {}

    has(key: string): boolean{
        return this.storage.has(key);
    }

    set(key: string, value: any): void{
        this.storage.set(key, value);
    }

    get(key: string): any{
        return this.storage.get(key);
    }

    remove(key: string): void{
        this.storage.remove(key);
    }

    getStorage(namespace: string): AppStorageSession{
        return new AppStorageSession(this, namespace);
    }
}

export class AppStorageLocal{
    private readonly namespace: string;
    private service: AppStorageServiceLocal;

    constructor(service: AppStorageServiceLocal, namespace: string){
        this.service = service;
        this.namespace = namespace;
    }

    private createKey(key: string): string{
        return this.namespace + '-' + key;
    }

    has(key: string): boolean{
        return this.service.has(this.createKey(key));
    }

    remove(key: string): void{
        return this.service.remove(this.createKey(key));
    }

    set(key: string, value: any): void{
        this.service.set(this.createKey(key), value);
    }

    get<T>(key: string): T{
        return this.service.get(this.createKey(key));
    }
}

export class AppStorageSession{
    private readonly namespace: string;
    private service: AppStorageServiceSession;

    constructor(service: AppStorageServiceSession, namespace: string){
        this.service = service;
        this.namespace = namespace;
    }

    private createKey(key: string): string{
        return this.namespace + '-' + key;
    }

    has(key: string): boolean{
        return this.service.has(this.createKey(key));
    }

    remove(key: string): void{
        return this.service.remove(this.createKey(key));
    }

    set(key: string, value: any): void{
        this.service.set(this.createKey(key), value);
    }

    get<T>(key: string): T{
        return this.service.get(this.createKey(key));
    }
}
