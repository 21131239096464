<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="logo ml-16">
                    <a routerLink="/"><img class="logo-icon" src="/assets/images/logos/coinomi-logo-brand.svg"></a>
                    <p *ngIf="!production"><span class="text-boxed" matTooltip="This is a Development site. Nothing you see here is real. Go away!">Develop</span></p>
                </div>
            </div>

        </div>


        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <div class="toolbar-separator" *ngIf="!hiddenNavbar" fxHide.gt-md></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
