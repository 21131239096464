<h2 mat-dialog-title>Terms of Service</h2>
<mat-dialog-content class="mat-typography">
    <p class="font-size-12">If you continue, you agree to proceed to {{data.serviceName}}, a third party service.</p>
    <p class="font-size-12">
        All your interactions with {{data.serviceName}} are outside of Coinomi's control and are subject to {{data.serviceName}}'s Terms of Service.
        Coinomi will not provide any personal information to {{data.serviceName}}.
        All information that you may provide to {{data.serviceName}} will be subject to {{data.serviceName}}'s Privacy Policy.
        Any information that may be collected, stored, or used by Coinomi will be subject to Coinomi's Privacy Policy.
    </p>

    <p class="font-size-12">
        COINOMI ACCEPTS NO RESPONSIBILITY AND WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE WHATSOEVER SUFFERED AS A RESULT OF ACCESSING, USE OF, OR RELIANCE UPON {{data.serviceName | uppercase}}'S INFORMATION AND SERVICES.
    </p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>Agree</button>
</mat-dialog-actions>