import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CnmIconComponent} from './icon/icon.component';
import { CnmSelectAccountComponent } from './select-account/select-account.component';
import {FormsModule} from '@angular/forms';
import { CnmSendTransactionComponent } from './send-transaction/send-transaction.component';
import {QRCodeModule} from 'angularx-qrcode';
import {FlexModule} from '@angular/flex-layout';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {AppStorageServiceLocal} from './app-storage.service';
import {AppStorageServiceSession} from './app-storage.service';
import {CnmTermsDialogComponent} from './terms-dialog/terms-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {AppTermsService} from './app-terms.service';
import {CnmDialogComponent} from './dialog/cnm-dialog.component';


@NgModule({
    providers: [
        AppStorageServiceLocal,
        AppStorageServiceSession,
        AppTermsService
    ],
    declarations: [
        CnmIconComponent,
        CnmSelectAccountComponent,
        CnmTermsDialogComponent,
        CnmDialogComponent,
        CnmSendTransactionComponent,
    ],
    imports: [
        CommonModule,
        MatSelectModule,
        FormsModule,
        MatInputModule,
        MatListModule,
        MatCardModule,
        MatDialogModule,
        QRCodeModule,
        FlexModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        CnmIconComponent,
        CnmSelectAccountComponent,
        CnmSendTransactionComponent
    ],
    entryComponents: [
        CnmSendTransactionComponent,
        CnmTermsDialogComponent,
        CnmDialogComponent
    ],

})
export class CoinomiModule {
}
