import { Injectable, NgZone, OnInit } from '@angular/core';
import { FuseConfigService } from '../../@fuse/services/config.service';
import {
    CnmSendTransactionComponent,
    SendTransactionData
} from '../coinomi/send-transaction/send-transaction.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CoinomiMockBridge } from './coinomi-mock-bridge';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { FioPubAddress } from '../main/fio/fio.service';
import {  SendBTCDirectCryptSdkRequest, OpenLinkInBrowserRequest } from 'app/main/btc-direct/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { CnmDialogComponent } from 'app/coinomi/dialog/cnm-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class CoinomiSdkService {
    public events$ = new Subject<{ event: string, data: any }>();
    private runsOnCoinomi = false;
    private CoinomiSDK: any;
    private supportedSDKVersion = 4;
    private isSupportedCoinomiVersion = false;
    // public coinTypesMapping: CoinType[];

    constructor(private _fuseConfigService: FuseConfigService,
        private bottomSheet: MatBottomSheet,
        private snackBar: MatSnackBar,
        private router: Router,
        private zone: NgZone,
        private dialog: MatDialog) {
        const url = window.location.href;

        // if (url.indexOf('?mockCoinomiDevice') !== -1){
        // Enable Mock Bridge
        //    new CoinomiMockBridge(bottomSheet).mock();
        // }

        // @ts-ignore        
        if (window.Coinomi) {
            this.runsOnCoinomi = true;

            // @ts-ignore
            this.CoinomiSDK = window.Coinomi;

            this.CoinomiSDK.addListener((event, data) => {
                zone.run(() => {
                    this.events$.next({ event: event, data: data });
                });
            });

            // @ts-ignore
            if (window.Coinomi.getSDKVersion() === this.supportedSDKVersion) {
                this.isSupportedCoinomiVersion = true;
            } else {
                const dialogRef = this.dialog.open(CnmDialogComponent, {
                    data: { title: 'Update App', message: 'You have an old version of Coinomi, please update!', options: { disableCancel: true } }
                });

                dialogRef.afterClosed().subscribe(action => {
                    this.CoinomiSDK.closeCoinomiServices();
                });
            }
        }



        // this.coinTypesMapping = [
        //     // {
        //     //     id: 'bitcoin.main',
        //     //     name: 'Bitcoin',
        //     //     symbol: 'BTC'
        //     // },
        //     {
        //         id: 'bitcoin.test',
        //         name: 'Bitcoin',
        //         symbol: 'BTC'
        //     },
        //     {
        //         id: 'ethereum.main',
        //         name: 'Ethereum',
        //         symbol: 'ETH'
        //     },
        //     {
        //         id: 'fio.main',
        //         name: 'FIO',
        //         symbol: 'FIO'
        //     },
        //     {
        //         id: 'binance.main',
        //         name: 'BNB',
        //         symbol: 'BNB'
        //     },
        //     {
        //         id: 'ripple.main',
        //         name: 'XRP',
        //         symbol: 'XRP'
        //     },
        //     {
        //         id: 'btccash.main',
        //         name: 'BitcoinCash',
        //         symbol: 'BCH'
        //     },
        //     {
        //         id: 'litecoin.main',
        //         name: 'Litcoin',
        //         symbol: 'LTC'
        //     },
        //     {
        //         id: '0xdac17f958d2ee523a2206206994597c13d831ec7.ethereum.main',
        //         name: 'USDT',
        //         symbol: 'USDT'
        //     },
        //     {
        //         id: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.ethereum.main',
        //         name: 'USDC',
        //         symbol: 'USDC'
        //     },
        //     {
        //         id: 'algorand.main',
        //         name: 'Algorand',
        //         symbol: 'ALGO'
        //     }
        // ];
    }

    // Used only for UI to hide the toolbar
    onCoinomi(): boolean {
        return this.runsOnCoinomi;
    }

    isCoinomi(): boolean {
        return this.isSupportedCoinomiVersion; //this.CoinomiSDK || false;
    }

    setViewSettings(title: string, sidebarEnabled: boolean): void {
        if (this.isCoinomi()) {
            // @ts-ignore
            try {
                this.CoinomiSDK.setViewSettings({ title: title, sidebarEnabled: sidebarEnabled });
            } catch (e) {
                console.log(e);
            }
        }
    }

    createUrl(url: string, options?: { isCallback?: boolean, forceDeviceScheme?: boolean }): string {
        let scheme = 'https://';

        if (this.isCoinomi() || options.forceDeviceScheme) {
            scheme = options.isCallback ? 'coinomi-callback://' : 'coinomi-services://';
        }

        const domain = environment.production ? 'services.coinomi.com' : 'services-dev.coinomi.net';

        return scheme + domain + url;
    }

    addCoin(coinType: CoinSettings): Promise<CoinAccount> {
        return this.CoinomiSDK.addCoin(coinType);
    }

    getCoinAccounts(): Promise<CoinAccount[]> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getCoinAccounts().then((coinAccounts) => {
                resolve(coinAccounts);
            }, (err) => {
                console.log(err);
            });
        });
    }

    addAddressesOnFio(addressesToRegister: Array<AddressToAddOnFio>): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.addAddressesOnFio(addressesToRegister).then((response) => {
                resolve(response);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }


    getFormattedTokenCodeForFio(token_code: string){
        token_code  =token_code.replace("-", "");
        token_code = token_code.replace(".", "")
        return token_code;
    }

    getFioRequests(fioPubKey: FioPubAddress): Promise<AllFioRequests> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getFioRequests(fioPubKey).then((fioRequests) => {
                resolve(fioRequests);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    approveFioRequest(fioRequest: FioRequest): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.approveFioRequest(fioRequest).then((approveResponse) => {
                resolve(approveResponse);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }


    sendBtcDirectSellCrypto(request: SendBTCDirectCryptSdkRequest): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.sendBtcDirectSellCrypto(request).then((approveResponse) => {
                resolve(approveResponse);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }


    openLinkInBrowser(request: OpenLinkInBrowserRequest): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.openLinkInBrowser(request).then((approveResponse) => {
                resolve(approveResponse);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    getMaxBalanceForCoinAccount(request: GetMaxBalanceForCoinAccountRequest): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getMaxBalanceForCoinAccount(request).then((balance) => {
                resolve(balance);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    rejectFioRequest(fioRequest: FioRequest): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.rejectFioRequest(fioRequest).then((rejectResponse) => {
                resolve(rejectResponse);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    getCoinAccountsForFioRequest(fioPubKey: FioPubAddress): Promise<CoinAccount[]> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getCoinAccountsForFioRequest(fioPubKey).then((coinAccounts) => {
                resolve(coinAccounts);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    getSelectedFiatCurrency(): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getSelectedFiatCurrency().then((fiatCurrency) => {
                resolve(fiatCurrency);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    fetchRate(rate: Rate): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.fetchRate(rate).then((rateResponse) => {
                resolve(rateResponse);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    checkNewFioRequest(fioNewRequest: FioNewRequest): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.checkNewFioRequest(fioNewRequest).then((checkResponse) => {
                resolve(checkResponse);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    requestFunds(fioNewRequest: FioNewRequest): Promise<String> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.requestFunds(fioNewRequest).then((requestFundsReply) => {
                resolve(requestFundsReply);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    getCoinAccountsForCoinTypes(coinTypes: CoinSettings[] | string[]): Promise<CoinAccount[]> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getCoinAccountsForCoinTypes(coinTypes).then((coinAccounts) => {
                resolve(coinAccounts);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    getCoinAccountsForCoinSymbols(coinSymbols: string[]): Promise<CoinAccount[]> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getCoinAccountsForCoinSymbols(coinSymbols).then((coinAccounts) => {
                resolve(coinAccounts);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }


    getWallet(): Promise<Wallet> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getWallet().then((wallet) => {
                resolve(wallet);
            }, (err) => {
                reject(err);
            });
        });
    }

    getInstallationId(): Promise<InstallationId> {
        return new Promise((resolve, reject) => {
            return this.CoinomiSDK.getInstallationId().then((data) => {

                // (async () => {
                //     const publicKey = '2e2259351198424bb6c29382cd18388fcb8ad89e25773df2d54585bc4c18fdc3';
                //
                //     function toHex(str): string {
                //         let result = '';
                //         for (let i = 0; i < str.length; i++) {
                //             result += str.charCodeAt(i).toString(16);
                //         }
                //         return result;
                //     }
                //
                //     const message = toHex(data.installationId);
                //     const isSigned = await ed.verify(data.signature, message, publicKey);
                // })();

                resolve(data);
            }, (err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    sendTransaction(data: SendTransactionData): Promise<any> {
        if (this.isCoinomi()) {
            return new Promise((resolve, reject) => {

                return this.CoinomiSDK.sendTransaction(data.transaction).then((coinAccounts) => {
                    // resolve(coinAccounts);
                    console.log(coinAccounts);
                    resolve(coinAccounts);
                }, (err) => {
                    console.log(err);
                    reject(err);

                });
            });

        } else {
            return new Promise((resolve, reject) => {
                const bottomSheetRef = this.bottomSheet.open(CnmSendTransactionComponent, {
                    data: data,
                    disableClose: true
                });

                bottomSheetRef.afterDismissed().subscribe((success) => {
                    console.log('Bottom sheet has been dismissed. ' + success);
                    if (success) {
                        resolve(success);
                    } else {
                        reject(false);
                    }
                });
            });
        }
    }
}

// https://medium.com/@naveen.kr/rxjs-custom-pipeable-operator-to-run-asynchronous-callbacks-inside-angular-zone-a49bd71c0bf6
export function enterZone(zone: NgZone): any {
    return <T>(source: Observable<T>) =>
        new Observable<T>(observer =>
            source.subscribe({
                next: (x) => zone.run(() => observer.next(x)),
                error: (err) => observer.error(err),
                complete: () => observer.complete()
            })
        );
}

export class InstallationId {
    installationId: string;
    signature: string;
}

export class Wallet {
    id: string;
    name: string;
}

export class CoinSettings {
    id?: string;
    symbol?: string;
    bip44Index?: number;
}

export class AppTransaction {
    category: 'transfer' = 'transfer';
    coinAccountFrom?: AccountDescription;
    receiverAddress: string;
    sendValue?: string;
    paymentId?: string;
    destinationTag?: string;
    invoiceId?: string;
    message?: string;
    contractData?: string;
    gasLimit?: string;
    emptyWallet?: boolean;

    createUri(): string {
        return this.coinAccountFrom.coinType.uri + ':' + this.receiverAddress + (this.sendValue ? '?amount=' + this.sendValue : this.emptyWallet ? '?cnm-max=true' : '');
    }
}

export interface CoinTypeDescription {
    id: string;
    symbol?: string;
    uri?: string;
}

export interface AccountDescription {
    coinType: CoinTypeDescription;
}

export class CoinAccount implements AccountDescription {
    id: string;
    name: string;
    symbol: string;
    icon: string;
    address: string; // TODO do we want it here?
    zeroAddress: string; // TODO do we want it here?
    uri: string;
    coinType: CoinType;
    fioAddressOfCoinAccount: string;
    symbolAndFioAddressAsId: string;
    balance?: string;
    unlockedBalance?: string;
    reservedBalance?: string;
}


export class EnabledIbanAccount {
    number: string;
    holder: string;
    verified: boolean;
}

export class CoinType implements CoinTypeDescription {
    id: string;
    name?: string;
    symbol: string;
    icon?: string;
    uri?: string;
    parentCoinType?: CoinType;
}

export class AddressToAddOnFio {
    chain_code: string;
    token_code: string;
    public_address: string;
    fio_address: string;
}

export class AllFioRequests {
    pendingRequests: FioRequest[];
    sentRequests: FioRequest[];
}

export class FioRequest {
    fio_request_id: string;
    timestamp: string;
    payeeFioAddress: string;
    payeeFioPublicKey: string;
    payerFioAddress: string;
    payerFioPublicKey: string;
    amount: string;
    memo: string;
    payeeTokenPublicAddress: string;
    tokenCode: string;
    chainCode: string;
    responseCode: number;
    errorMessage: string;
    type: string;
    status?: 'requested' | 'sent_to_blockchain' | 'request_rejected';
}

export class FioNewRequest {
    payeeFioPubKey: string;
    payeeTokenPublicAddress: string;
    payeeFioAddress: string;
    payerFioAddress: string;
    amount: number;
    memo: string;
    tokenCode: string;
    chainCode: string;
}

export class Rate {
    sourceType: string;
    sourceAmount: string;
    sourceSymbol: string;
    destinationSymbol: string;
}


export interface GetMaxBalanceForCoinAccountRequest{
    coinAccountId: string;
    tokenCode: string;
    coinAccountAddress: string;
    toWalletAddress: string;
    destinationTag?: number;
    coinId: string;
}
