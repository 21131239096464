import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'cnm-terms-dialog',
    templateUrl: './terms-dialog.component.html'
})
export class CnmTermsDialogComponent{
    constructor(@Inject(MAT_DIALOG_DATA) public data: CnmTermsDialogData) {}
}

export interface CnmTermsDialogData {
    serviceName: string;
}
