<div class="page-layout cnm-service fullwidth" fusePerfectScrollbar>

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- CONTENT HEADER -->
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="center center">
            <h2 class="m-0">Coinomi Services</h2>
        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content cnm-service-content">
                <div class="cnm-top">

                </div>

                <div class="cnm-center" fxLayoutAlign="center center">

                    <p class="text-center">Oops! You appear to be lost.<br />Why not check out <a class="coinomi" href="https://www.coinomi.com">coinomi.com</a></p>

                </div>

                <div class="cnm-bottom">

                </div>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>

