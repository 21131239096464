import {Component, OnInit} from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import {CoinomiSdkService, InstallationId, Wallet} from '../../coinomi-sdk/coinomi-sdk.service';

@Component({
    selector   : 'sample',
    templateUrl: './sample.component.html',
})
export class SampleComponent implements OnInit {
    public installationId: Promise<InstallationId>;
    public wallet: Promise<Wallet>;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private coinomiSdkService: CoinomiSdkService
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }

    ngOnInit(): void {
        this.coinomiSdkService.setViewSettings('Installation Id', false);
        this.installationId = this.coinomiSdkService.getInstallationId();
        this.wallet = this.coinomiSdkService.getWallet();
    }
}
