<div class="page-layout cnm-service fullwidth" fusePerfectScrollbar>

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- CONTENT HEADER -->
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="center center">
            <h2 class="m-0">Full width with content scroll</h2>
        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content cnm-service-content">
                <div class="cnm-top">
                    Top
                </div>

                <div class="cnm-center" fxLayout="column" fxLayoutAlign="center center">
                    Center
                </div>

                <div class="cnm-bottom">
                    Bottom
                </div>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>

