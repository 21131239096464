<div class="page-layout cnm-service fullwidth" fusePerfectScrollbar>

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <div class="center">

        <button mat-icon-button class="cnm-sidebar-toggle sidebar-toggle" (click)="toggleSidebar()" fxHide.gt-md>
            <mat-icon>menu</mat-icon>
        </button>

        <!-- CONTENT HEADER -->
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="center center">
            <h2 class="m-0">Full width with content scroll</h2>
        </div>
        <!-- / CONTENT HEADER -->

        <div class="cnm-container">

            <!-- SIDEBAR -->
            <div class="cnm-sidebar">
                <fuse-sidebar name="sample-sidebar" invisibleOverlay="true" class="navbar-fuse-sidebar grey-800" lockedOpen="gt-md">
                    <div class="navbar-content ps" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
                        <fuse-navigation [navigation]="nav" layout="vertical"></fuse-navigation>
                    </div>
                </fuse-sidebar>
            </div>
            <!-- / SIDEBAR -->

            <!-- CENTER -->

            <!-- CONTENT CARD -->
            <div class="content-card">

                <!-- CONTENT -->
                <div class="content cnm-service-content">
                    <div class="cnm-top">
                        top
                    </div>

                    <div class="cnm-center">
                        center
                    </div>

                    <div class="cnm-bottom">
                        bottom
                    </div>

                </div>
                <!-- / CONTENT -->

            </div>
            <!-- / CONTENT CARD -->

        </div>

    </div>
    <!-- / CENTER -->

</div>

