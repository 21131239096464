<svg *ngIf="icon == 'brand'" [ngClass]="color" version="1.0"
     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
     x="0px" y="0px" width="252.562px" height="57.846px" viewBox="0 0 252.562 57.846"
     enable-background="new 0 0 252.562 57.846"
     xml:space="preserve">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.664,45.086v9.168c-4.096,2.332-8.065,3.498-11.909,3.498
            c-5.262,0-9.862-1.986-13.816-5.94C1.985,47.843,0,43.259,0,38.059c0-5.922,1.969-10.821,5.908-14.728
            c3.937-3.891,8.884-5.845,14.824-5.845c3.528,0,7.183,0.914,10.932,2.741v8.979c-4.033-2.52-7.845-3.78-11.453-3.78
            c-3.323,0-6.222,1.166-8.726,3.513c-2.49,2.331-3.735,5.072-3.735,8.223c0,3.528,1.166,6.506,3.514,8.933
            c2.331,2.409,5.198,3.622,8.585,3.622C23.299,49.717,27.238,48.174,31.664,45.086 M34.407,37.509c0-5.23,2.047-9.862,6.159-13.927
            c4.111-4.064,8.837-6.096,14.162-6.096c5.482,0,10.27,2.001,14.367,6.003c4.095,4.001,6.144,8.664,6.144,14.02
            c0,5.372-2.017,10.097-6.035,14.193c-4.016,4.096-8.648,6.144-13.924,6.144c-5.624,0-10.524-2.016-14.667-6.065
            S34.407,42.974,34.407,37.509 M67.488,37.524c0-3.26-1.229-6.08-3.703-8.491c-2.457-2.41-5.387-3.607-8.774-3.607
            c-3.481,0-6.474,1.213-8.994,3.654c-2.506,2.442-3.766,5.341-3.766,8.727c0,3.23,1.277,6.05,3.813,8.476
            c2.536,2.41,5.529,3.623,8.947,3.623c3.324,0,6.238-1.244,8.728-3.718C66.243,43.7,67.488,40.816,67.488,37.524 M79.414,6.144
            c0-1.67,0.615-3.103,1.843-4.316C82.502,0.615,83.951,0,85.653,0c1.67,0,3.103,0.615,4.316,1.828
            c1.213,1.213,1.827,2.646,1.827,4.316c0,1.686-0.614,3.135-1.827,4.348c-1.213,1.197-2.646,1.796-4.316,1.796
            c-1.702,0-3.151-0.599-4.396-1.796C80.029,9.279,79.414,7.83,79.414,6.144 M81.683,18.62h8.034v38.091h-8.034V18.62z M97.217,56.712
            V18.62h8.034v6.318c2.283-2.552,4.364-4.427,6.222-5.64c1.859-1.213,3.938-1.812,6.223-1.812c3.419,0,6.348,1.134,8.774,3.419
            c2.426,2.284,3.639,5.025,3.639,8.239v27.568h-8.033V31.475c0-2.08-0.663-3.78-2.001-5.151c-1.324-1.355-3.009-2.032-5.057-2.032
            c-2.662,0-4.946,1.134-6.885,3.403c-1.922,2.268-2.882,5.009-2.882,8.222v20.795H97.217z M135.041,37.509
            c0-5.23,2.048-9.862,6.16-13.927c4.112-4.064,8.837-6.096,14.162-6.096c5.482,0,10.27,2.001,14.367,6.003
            c4.096,4.001,6.143,8.664,6.143,14.02c0,5.372-2.016,10.097-6.034,14.193c-4.016,4.096-8.648,6.144-13.925,6.144
            c-5.624,0-10.523-2.016-14.667-6.065C137.105,47.732,135.041,42.974,135.041,37.509 M168.122,37.524c0-3.26-1.228-6.08-3.701-8.491
            c-2.458-2.41-5.387-3.607-8.775-3.607c-3.481,0-6.474,1.213-8.994,3.654c-2.505,2.442-3.766,5.341-3.766,8.727
            c0,3.23,1.276,6.05,3.813,8.476c2.536,2.41,5.529,3.623,8.947,3.623c3.324,0,6.238-1.244,8.727-3.718
            C166.878,43.7,168.122,40.816,168.122,37.524 M180.806,56.712V18.62h8.033v5.766c1.86-2.394,3.655-4.143,5.341-5.246
            c1.701-1.102,3.655-1.654,5.86-1.654c2.868,0,5.136,0.631,6.758,1.891c1.639,1.26,3.025,3.276,4.159,6.049
            c2-2.773,3.938-4.789,5.813-6.049c1.875-1.26,4.033-1.891,6.506-1.891c3.024,0,5.639,1.134,7.829,3.403s3.293,4.947,3.293,8.066
            v27.757h-8.034V30.94c0-4.238-2.08-6.365-6.239-6.365c-2.379,0-4.379,1.087-6.033,3.245c-1.654,2.174-2.474,4.852-2.474,8.035
            v20.857h-8.034V32.074c0-4.994-2.031-7.499-6.096-7.499c-2.41,0-4.458,1.024-6.128,3.057c-1.686,2.031-2.521,4.552-2.521,7.545
            v21.535H180.806z M240.18,6.144c0-1.67,0.614-3.103,1.844-4.316C243.268,0.615,244.717,0,246.418,0c1.67,0,3.104,0.615,4.317,1.828
            c1.213,1.213,1.827,2.646,1.827,4.316c0,1.686-0.614,3.135-1.827,4.348c-1.213,1.197-2.647,1.796-4.317,1.796
            c-1.701,0-3.15-0.599-4.394-1.796C240.794,9.279,240.18,7.83,240.18,6.144 M242.449,18.62h8.034v38.091h-8.034V18.62z"/>
        </svg>


<svg *ngIf="icon == 'logo'" [ngClass]="color" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     width="114.72px" height="114.72px" viewBox="0 0 114.72 114.72" enable-background="new 0 0 114.72 114.72"
     xml:space="preserve">
<g>
	<defs>
		<rect id="SVGID_1_" width="114.72" height="114.72"/>
	</defs>
    <clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_1_" overflow="visible"/>
	</clipPath>
    <path clip-path="url(#SVGID_2_)" fill-rule="evenodd" clip-rule="evenodd" fill="#D8D8D8" d="M57.36,0
		c31.679,0,57.36,25.681,57.36,57.36c0,31.68-25.681,57.36-57.36,57.36S0,89.04,0,57.36C0,25.681,25.681,0,57.36,0"/>
    <path clip-path="url(#SVGID_2_)" fill-rule="evenodd" clip-rule="evenodd" fill="#FDFDFD" d="M57.36,5.386
		c28.704,0,51.974,23.27,51.974,51.974c0,28.705-23.27,51.974-51.974,51.974S5.386,86.065,5.386,57.36
		C5.386,28.656,28.656,5.386,57.36,5.386"/>
    <path clip-path="url(#SVGID_2_)" fill-rule="evenodd" clip-rule="evenodd" fill="#AAAAAA" d="M57.36,4.441
		c14.612,0,27.843,5.924,37.419,15.501c9.576,9.576,15.499,22.806,15.499,37.418c0,14.613-5.923,27.843-15.499,37.419
		c-9.576,9.577-22.807,15.5-37.419,15.5c-14.613,0-27.843-5.923-37.419-15.5c-9.577-9.576-15.5-22.807-15.5-37.419
		s5.923-27.842,15.5-37.418C29.517,10.365,42.747,4.441,57.36,4.441 M93.443,21.277C84.209,12.043,71.451,6.331,57.36,6.331
		c-14.092,0-26.849,5.712-36.084,14.946C12.043,30.511,6.331,43.269,6.331,57.36c0,14.092,5.712,26.85,14.945,36.083
		c9.235,9.234,21.992,14.946,36.084,14.946c14.091,0,26.849-5.712,36.083-14.946c9.234-9.233,14.946-21.991,14.946-36.083
		C108.389,43.269,102.677,30.511,93.443,21.277"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_3_" x="27.822" y="77.202" transform="matrix(-0.5 -0.866 0.866 -0.5 -9.6022 150.7993)"
                  width="21.815" height="1.939"/>
	</defs>
        <clipPath id="SVGID_4_">
		<use xlink:href="#SVGID_3_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(10.1289 17.5437 17.5437 -10.1289 -1978.9517 1231.3842)">
		<stop offset="0" style="stop-color:#F89200"/>
            <stop offset="1" style="stop-color:#00BEF2"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_4_)" fill="url(#SVGID_5_)"
                 points="41.876,62.79 53.623,83.137 35.583,93.552 23.836,73.206 	"/>
</g>
    <g>
	<defs>
		<rect id="SVGID_6_" x="28.1" y="33.471" width="1.937" height="19.555"/>
	</defs>
        <clipPath id="SVGID_7_">
		<use xlink:href="#SVGID_6_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-5.267087e-06" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(0 -19.5548 -19.5548 0 2272.3948 53.0259)">
		<stop offset="0" style="stop-color:#F89200"/>
            <stop offset="1" style="stop-color:#CC1F25"/>
	</linearGradient>
        <rect x="28.1" y="33.471" clip-path="url(#SVGID_7_)" fill="url(#SVGID_8_)" width="1.937" height="19.555"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_9_" x="40.619" y="39.626"
                  transform="matrix(-0.7074 -0.7068 0.7068 -0.7074 36.4283 112.9194)" width="1.938" height="18.586"/>
	</defs>
        <clipPath id="SVGID_10_">
		<use xlink:href="#SVGID_9_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(11.9017 -11.9017 -11.9017 -11.9017 1400.9971 1420.2292)">
		<stop offset="0" style="stop-color:#F89200"/>
            <stop offset="1" style="stop-color:#AAAAAA"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_10_)" fill="url(#SVGID_11_)" points="27.076,48.919 41.588,34.407 56.101,48.919 41.588,63.432
		"/>
</g>
    <g>
	<defs>
		<rect id="SVGID_12_" x="37.482" y="60.469" width="25.045" height="1.938"/>
	</defs>
        <clipPath id="SVGID_13_">
		<use xlink:href="#SVGID_12_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-9.094947e-13" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(25.0452 0 0 -25.0452 37.482 2934.6238)">
		<stop offset="0" style="stop-color:#F89200"/>
            <stop offset="1" style="stop-color:#3273E2"/>
	</linearGradient>
        <rect x="37.482" y="60.469" clip-path="url(#SVGID_13_)" fill="url(#SVGID_14_)" width="25.045" height="1.938"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_15_" x="34.114" y="73.036" transform="matrix(-0.866 -0.5 0.5 -0.866 57.8558 163.514)"
                  width="33.443" height="1.939"/>
	</defs>
        <clipPath id="SVGID_16_">
		<use xlink:href="#SVGID_15_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(27.5772 15.9217 15.9217 -27.5772 -1789.4932 3229.7058)">
		<stop offset="0" style="stop-color:#F89200"/>
            <stop offset="1" style="stop-color:#A680D0"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_16_)" fill="url(#SVGID_17_)" points="43.353,51.846 73.768,69.406 58.318,96.167 27.903,78.606
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_18_" x="57.163" y="80.125" transform="matrix(-0.348 -0.9375 0.9375 -0.348 -3.7183 172.5089)"
                  width="1.937" height="14.844"/>
	</defs>
        <clipPath id="SVGID_19_">
		<use xlink:href="#SVGID_18_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(12.8223 -4.7635 -4.7635 -12.8223 598.1843 1560.9052)">
		<stop offset="0" style="stop-color:#00BEF2"/>
            <stop offset="1" style="stop-color:#A680D0"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_19_)" fill="url(#SVGID_20_)" points="48.557,84.902 63.657,79.292 67.706,90.193 52.606,95.803
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_21_" x="77.537" y="74.204"
                  transform="matrix(-0.1878 -0.9822 0.9822 -0.1878 12.8223 174.3672)" width="1.938" height="15.357"/>
	</defs>
        <clipPath id="SVGID_22_">
		<use xlink:href="#SVGID_21_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(-14.4125 2.7535 2.7535 14.4125 -230.172 -1572.8979)">
		<stop offset="0" style="stop-color:#FF4C9F"/>
            <stop offset="1" style="stop-color:#A680D0"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_22_)" fill="url(#SVGID_23_)"
                 points="87.112,84.106 71.326,87.122 69.9,79.659 85.686,76.643 	"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_24_" x="40.141" y="15.966"
                  transform="matrix(-0.3591 -0.9333 0.9333 -0.3591 31.8453 73.3557)" width="1.938" height="19.555"/>
	</defs>
        <clipPath id="SVGID_25_">
		<use xlink:href="#SVGID_24_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(-17.1131 6.5857 6.5857 17.1131 -705.8453 -1940.7688)">
		<stop offset="0" style="stop-color:#5CD551"/>
            <stop offset="1" style="stop-color:#CE0003"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_25_)" fill="url(#SVGID_26_)" points="53.543,29.021 34.081,36.511 28.677,22.467 48.139,14.977
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_27_" x="51.461" y="24.165"
                  transform="matrix(-0.9966 -0.0825 0.0825 -0.9966 102.2795 62.424)" width="1.939" height="9.866"/>
	</defs>
        <clipPath id="SVGID_28_">
		<use xlink:href="#SVGID_27_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(0.7902 -9.5238 -9.5238 -0.7902 1144.6002 124.5126)">
		<stop offset="0" style="stop-color:#AAAAAA"/>
            <stop offset="0.1837" style="stop-color:#A2AEA1"/>
            <stop offset="0.482" style="stop-color:#8DBA89"/>
            <stop offset="0.8557" style="stop-color:#6BCD62"/>
            <stop offset="1" style="stop-color:#5CD551"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_28_)" fill="url(#SVGID_29_)"
                 points="50.233,34.026 51.075,23.876 54.627,24.17 53.785,34.32 	"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_30_" x="54.594" y="27.801"
                  transform="matrix(-0.9141 -0.4054 0.4054 -0.9141 122.9882 83.5885)" width="31.504" height="1.938"/>
	</defs>
        <clipPath id="SVGID_31_">
		<use xlink:href="#SVGID_30_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(-27.5434 -12.2152 -12.2152 27.5434 1485.4491 -3124.9033)">
		<stop offset="0" style="stop-color:#F8BF00"/>
            <stop offset="1" style="stop-color:#5CD551"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_31_)" fill="url(#SVGID_32_)" points="80.276,47.006 50.164,33.652 60.416,10.534 90.528,23.888
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_33_" x="69.442" y="24.08" transform="matrix(-0.047 -0.9989 0.9989 -0.047 35.7046 110.1757)"
                  width="1.938" height="27.951"/>
	</defs>
        <clipPath id="SVGID_34_">
		<use xlink:href="#SVGID_33_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(-27.7426 1.2972 1.2972 27.7426 -64.5311 -3145.2249)">
		<stop offset="0" style="stop-color:#F8BF00"/>
            <stop offset="0.6" style="stop-color:#B6AD91"/>
            <stop offset="1" style="stop-color:#AAAAAA"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_34_)" fill="url(#SVGID_35_)" points="84.568,39.669 56.467,40.983 56.255,36.442 84.356,35.128
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_36_" x="58.461" y="71.793" transform="matrix(-0.106 -0.9944 0.9944 -0.106 1.5145 146.8847)"
                  width="16.659" height="1.938"/>
	</defs>
        <clipPath id="SVGID_37_">
		<use xlink:href="#SVGID_36_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(1.7228 16.1696 16.1696 -1.7228 -1789.0465 262.3179)">
		<stop offset="0" style="stop-color:#3273E2"/>
            <stop offset="0.99" style="stop-color:#A680D0"/>
            <stop offset="1" style="stop-color:#A680D0"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_37_)" fill="url(#SVGID_38_)" points="68.595,63.988 70.403,80.958 64.985,81.535 63.177,64.565
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_39_" x="49.77" y="49.978"
                  transform="matrix(-0.5279 -0.8493 0.8493 -0.5279 46.9709 128.0031)" width="18.586" height="1.937"/>
	</defs>
        <clipPath id="SVGID_40_">
		<use xlink:href="#SVGID_39_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(8.9723 14.4358 14.4358 -8.9723 -1601.5004 1073.0309)">
		<stop offset="0" style="stop-color:#AAAAAA"/>
            <stop offset="1" style="stop-color:#3273E2"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_40_)" fill="url(#SVGID_41_)" points="61.598,37.405 72.327,54.666 56.527,64.487 45.798,47.226
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_42_" x="86.46" y="41.882"
                  transform="matrix(-0.9887 -0.1502 0.1502 -0.9887 166.5703 109.7612)" width="1.938" height="13.42"/>
	</defs>
        <clipPath id="SVGID_43_">
		<use xlink:href="#SVGID_42_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(-1.925 12.7239 12.7239 1.925 -1371.299 -178.6099)">
		<stop offset="0" style="stop-color:#F8BF00"/>
            <stop offset="1" style="stop-color:#00BC9B"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_43_)" fill="url(#SVGID_44_)" points="91.396,42.118 89.303,55.952 83.462,55.069 85.555,41.235
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_45_" x="73.794" y="54.774"
                  transform="matrix(-0.0697 -0.9976 0.9976 -0.0697 19.2823 139.6567)" width="1.938" height="12.126"/>
	</defs>
        <clipPath id="SVGID_46_">
		<use xlink:href="#SVGID_45_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(-11.8334 0.8279 0.8279 11.8334 -14.2986 -1297.1072)">
		<stop offset="0" style="stop-color:#00BC9B"/>
            <stop offset="0.99" style="stop-color:#3273E2"/>
            <stop offset="1" style="stop-color:#3273E2"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_46_)" fill="url(#SVGID_47_)" points="81.072,62.212 68.707,63.078 68.454,59.461 80.819,58.595
		"/>
</g>
    <g>
	<defs>

			<rect id="SVGID_48_" x="81.43" y="69.889"
                  transform="matrix(-0.1673 -0.9859 0.9859 -0.1673 32.2741 168.9737)" width="12.125" height="1.938"/>
	</defs>
        <clipPath id="SVGID_49_">
		<use xlink:href="#SVGID_48_" overflow="visible"/>
	</clipPath>

        <linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="0" y1="114.72" x2="1" y2="114.72"
                        gradientTransform="matrix(1.9277 11.3568 11.3568 -1.9277 -1216.3198 286.3223)">
		<stop offset="0" style="stop-color:#00BC9B"/>
            <stop offset="1" style="stop-color:#FF4C9F"/>
	</linearGradient>
        <polygon clip-path="url(#SVGID_49_)" fill="url(#SVGID_50_)" points="89.352,64.068 91.488,76.653 85.632,77.647 83.496,65.062
		"/>
</g>
    <g>
	<defs>
		<rect id="SVGID_51_" width="114.72" height="114.72"/>
	</defs>
        <clipPath id="SVGID_52_">
		<use xlink:href="#SVGID_51_" overflow="visible"/>
	</clipPath>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#F89200" d="M29.9,52.691
		c4.832,0.459,8.376,4.747,7.917,9.579c-0.459,4.831-4.747,8.376-9.579,7.918c-4.831-0.459-8.376-4.748-7.917-9.579
		C20.78,55.777,25.068,52.233,29.9,52.691"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#00BC9B" d="M86.161,54.839
		c2.899,0.275,5.026,2.848,4.751,5.748c-0.275,2.898-2.849,5.025-5.748,4.75c-2.899-0.276-5.025-2.849-4.75-5.748
		C80.689,56.691,83.263,54.564,86.161,54.839"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#F8BF00" d="M89.665,31.913
		c2.899,0.275,5.026,2.849,4.75,5.747c-0.275,2.899-2.848,5.026-5.747,4.751c-2.899-0.275-5.026-2.849-4.75-5.748
		C84.193,33.765,86.766,31.638,89.665,31.913"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#5CD551" d="M53.42,17.635
		c1.933,0.184,3.351,1.899,3.167,3.832c-0.184,1.932-1.899,3.35-3.832,3.167c-1.932-0.184-3.35-1.899-3.167-3.832
		C49.772,18.87,51.487,17.452,53.42,17.635"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#FF4C9F" d="M89.306,76.391
		c1.933,0.184,3.351,1.899,3.167,3.832c-0.183,1.933-1.899,3.351-3.832,3.167c-1.932-0.184-3.35-1.898-3.167-3.831
		C85.658,77.625,87.373,76.208,89.306,76.391"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#00BEF2" d="M41.414,92.704
		c-0.484-2.871,1.451-5.592,4.322-6.076c2.871-0.485,5.592,1.45,6.076,4.322c0.485,2.871-1.451,5.591-4.322,6.075
		C44.619,97.511,41.898,95.575,41.414,92.704"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#CE0003" d="M32.362,31.641
		c-0.677,1.819-2.701,2.745-4.521,2.067c-1.819-0.677-2.744-2.701-2.067-4.52s2.701-2.745,4.521-2.068
		C32.114,27.798,33.04,29.822,32.362,31.641"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#AAAAAA" d="M53.79,43.706
		c-2.648,1.211-5.777,0.046-6.988-2.602c-1.211-2.648-0.046-5.777,2.602-6.988s5.777-0.046,6.988,2.602
		C57.603,39.367,56.438,42.495,53.79,43.706"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#3273E2" d="M64.357,64.733
		c-1.819-0.678-2.745-2.702-2.067-4.521c0.677-1.819,2.701-2.745,4.521-2.067c1.819,0.677,2.744,2.701,2.067,4.521
		C68.2,64.485,66.176,65.41,64.357,64.733"/>
        <path clip-path="url(#SVGID_52_)" fill-rule="evenodd" clip-rule="evenodd" fill="#A680D0" d="M65.255,86.134
		c-1.237-1.497-1.027-3.713,0.469-4.949c1.497-1.237,3.713-1.027,4.949,0.47c1.237,1.496,1.027,3.711-0.469,4.949
		C68.707,87.84,66.491,87.63,65.255,86.134"/>
</g>
</svg>


<svg *ngIf="icon == 'logo-brand'" [ngClass]="color" version="1.1"
     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
     x="0px" y="0px" width="390.9px" height="114.8px" viewBox="0 0 390.9 114.8"
     style="enable-background:new 0 0 390.9 114.8;"
     xml:space="preserve">
<style type="text/css">
	.st0 {
        fill: #D8D8D8;
    }

    .st1 {
        fill: #AAAAAA;
    }

    .st2 {
        fill: #FFFFFF;
    }

    .st3 {
        fill: url(#SVGID_1_);
    }

    .st4 {
        fill: url(#SVGID_2_);
    }

    .st5 {
        fill: url(#SVGID_3_);
    }

    .st6 {
        fill: url(#SVGID_4_);
    }

    .st7 {
        fill: url(#SVGID_5_);
    }

    .st8 {
        fill: url(#SVGID_6_);
    }

    .st9 {
        fill: url(#SVGID_7_);
    }

    .st10 {
        fill: url(#SVGID_8_);
    }

    .st11 {
        fill: url(#SVGID_9_);
    }

    .st12 {
        fill: url(#SVGID_10_);
    }

    .st13 {
        fill: url(#SVGID_11_);
    }

    .st14 {
        fill: url(#SVGID_12_);
    }

    .st15 {
        fill: url(#SVGID_13_);
    }

    .st16 {
        fill: url(#SVGID_14_);
    }

    .st17 {
        fill: url(#SVGID_15_);
    }

    .st18 {
        fill: url(#SVGID_16_);
    }

    .st19 {
        fill: #F89200;
    }

    .st20 {
        fill: #00BC9B;
    }

    .st21 {
        fill: #F8BF00;
    }

    .st22 {
        fill: #5CD551;
    }

    .st23 {
        fill: #FF4C9F;
    }

    .st24 {
        fill: #00BEF2;
    }

    .st25 {
        fill: #CE0003;
    }

    .st26 {
        fill: #3273E2;
    }

    .st27 {
        fill: #A680D0;
    }

    .st28 {
        fill: url(#SVGID_17_);
    }

    .st29 {
        fill: url(#SVGID_18_);
    }

    .st30 {
        fill: url(#SVGID_19_);
    }

    .st31 {
        fill: url(#SVGID_20_);
    }

    .st32 {
        fill: url(#SVGID_21_);
    }

    .st33 {
        fill: url(#SVGID_22_);
    }

    .st34 {
        fill: url(#SVGID_23_);
    }

    .st35 {
        fill: url(#SVGID_24_);
    }

    .st36 {
        fill: url(#SVGID_25_);
    }

    .st37 {
        fill: url(#SVGID_26_);
    }

    .st38 {
        fill: url(#SVGID_27_);
    }

    .st39 {
        fill: url(#SVGID_28_);
    }

    .st40 {
        fill: url(#SVGID_29_);
    }

    .st41 {
        fill: url(#SVGID_30_);
    }

    .st42 {
        fill: url(#SVGID_31_);
    }

    .st43 {
        fill: url(#SVGID_32_);
    }
</style>
    <defs>
</defs>
    <path d="M170.1,65.4v9.2c-4.1,2.3-8.1,3.5-11.9,3.5c-5.3,0-9.9-2-13.8-5.9c-4-4-5.9-8.6-5.9-13.8c0-5.9,2-10.8,5.9-14.7
	s8.9-5.8,14.8-5.8c3.5,0,7.2,0.9,10.9,2.7v9c-4-2.5-7.8-3.8-11.5-3.8c-3.3,0-6.2,1.2-8.7,3.5s-3.7,5.1-3.7,8.2
	c0,3.5,1.2,6.5,3.5,8.9s5.2,3.6,8.6,3.6C161.8,70,165.7,68.5,170.1,65.4L170.1,65.4z M172.9,57.8c0-5.2,2-9.9,6.2-13.9
	c4.1-4.1,8.8-6.1,14.2-6.1c5.5,0,10.3,2,14.4,6s6.1,8.7,6.1,14c0,5.4-2,10.1-6,14.2s-8.6,6.1-13.9,6.1c-5.6,0-10.5-2-14.7-6.1
	C174.9,68,172.9,63.3,172.9,57.8z M205.9,57.8c0-3.3-1.2-6.1-3.7-8.5s-5.4-3.6-8.8-3.6c-3.5,0-6.5,1.2-9,3.7
	c-2.5,2.4-3.8,5.3-3.8,8.7c0,3.2,1.3,6,3.8,8.5c2.5,2.4,5.5,3.6,8.9,3.6c3.3,0,6.2-1.2,8.7-3.7C204.7,64,205.9,61.1,205.9,57.8
	L205.9,57.8z M217.9,26.4c0-1.7,0.6-3.1,1.8-4.3s2.7-1.8,4.4-1.8s3.1,0.6,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.3c0,1.7-0.6,3.1-1.8,4.3
	c-1.2,1.2-2.6,1.8-4.3,1.8s-3.2-0.6-4.4-1.8C218.5,29.6,217.9,28.1,217.9,26.4z M220.1,38.9h8V77h-8V38.9z M235.7,77V38.9h8v6.3
	c2.3-2.6,4.4-4.4,6.2-5.6c1.9-1.2,3.9-1.8,6.2-1.8c3.4,0,6.3,1.1,8.8,3.4c2.4,2.3,3.6,5,3.6,8.2V77h-8V51.8c0-2.1-0.7-3.8-2-5.2
	s-3-2-5.1-2c-2.7,0-4.9,1.1-6.9,3.4c-1.9,2.3-2.9,5-2.9,8.2V77H235.7L235.7,77z M273.5,57.8c0-5.2,2-9.9,6.2-13.9
	c4.1-4.1,8.8-6.1,14.2-6.1c5.5,0,10.3,2,14.4,6s6.1,8.7,6.1,14c0,5.4-2,10.1-6,14.2s-8.6,6.1-13.9,6.1c-5.6,0-10.5-2-14.7-6.1
	C275.6,68,273.5,63.3,273.5,57.8L273.5,57.8z M306.6,57.8c0-3.3-1.2-6.1-3.7-8.5s-5.4-3.6-8.8-3.6c-3.5,0-6.5,1.2-9,3.7
	c-2.5,2.4-3.8,5.3-3.8,8.7c0,3.2,1.3,6,3.8,8.5c2.5,2.4,5.5,3.6,8.9,3.6c3.3,0,6.2-1.2,8.7-3.7C305.3,64,306.6,61.1,306.6,57.8z
	 M319.3,77V38.9h8v5.8c1.9-2.4,3.7-4.1,5.3-5.2c1.7-1.1,3.7-1.7,5.9-1.7c2.9,0,5.1,0.6,6.8,1.9c1.6,1.3,3,3.3,4.2,6
	c2-2.8,3.9-4.8,5.8-6c1.9-1.3,4-1.9,6.5-1.9c3,0,5.6,1.1,7.8,3.4s3.3,4.9,3.3,8.1V77h-8V51.2c0-4.2-2.1-6.4-6.2-6.4
	c-2.4,0-4.4,1.1-6,3.2c-1.7,2.2-2.5,4.9-2.5,8v21h-8V52.4c0-5-2-7.5-6.1-7.5c-2.4,0-4.5,1-6.1,3.1c-1.7,2-2.5,4.6-2.5,7.5V77H319.3
	L319.3,77z M378.6,26.4c0-1.7,0.6-3.1,1.8-4.3c1.2-1.2,2.7-1.8,4.4-1.8c1.7,0,3.1,0.6,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.3
	c0,1.7-0.6,3.1-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8c-1.7,0-3.2-0.6-4.4-1.8C379.3,29.6,378.6,28.1,378.6,26.4z M380.9,38.9h8V77h-8
	V38.9z"/>
    <path class="st0" d="M57.4,0C25.7,0,0,25.7,0,57.4s25.7,57.4,57.4,57.4s57.4-25.7,57.4-57.4C114.7,25.7,89,0,57.4,0z M57.4,109.3
	c-28.7,0-52-23.3-52-52s23.3-52,52-52s52,23.3,52,52C109.3,86.1,86.1,109.3,57.4,109.3z"/>
    <path class="st1" d="M57.4,4.4c14.6,0,27.8,5.9,37.4,15.5s15.5,22.8,15.5,37.4s-5.9,27.8-15.5,37.4S72,110.3,57.4,110.3
	S29.6,104.4,20,94.8S4.4,72,4.4,57.4S10.3,29.6,19.9,20S42.7,4.4,57.4,4.4z"/>
    <path class="st2" d="M93.4,21.3c-9.2-9.3-21.9-15-36-15S30.6,12,21.3,21.2c-9.3,9.3-15,22.1-15,36.2s5.7,26.8,15,36
	c9.2,9.2,22,14.9,36.1,14.9s26.8-5.7,36.1-14.9c9.2-9.2,14.9-22,14.9-36.1S102.7,30.5,93.4,21.3z"/>
    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="33.6522" y1="45.8682" x2="43.7812" y2="28.3252"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#F89200"/>
        <stop offset="1" style="stop-color:#00BEF2"/>
</linearGradient>
    <path class="st3" d="M32.4,69.2l1.7-1L45,87.1l-1.7,1L32.4,69.2z"/>
    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="29.05" y1="62.2496" x2="29.05" y2="81.8046"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#F89200"/>
        <stop offset="1" style="stop-color:#CC1F25"/>
</linearGradient>
    <path class="st4" d="M28.1,33.5H30v19.6h-1.9V33.5z"/>
    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="35.634" y1="60.4096" x2="47.535" y2="72.3116"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#F89200"/>
        <stop offset="1" style="stop-color:#AAAAAA"/>
</linearGradient>
    <path class="st5" d="M35.7,56.2l-1.4-1.4l13.2-13.1l1.3,1.3L35.7,56.2z"/>
    <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="37.5" y1="53.8256" x2="62.545" y2="53.8256"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#F89200"/>
        <stop offset="1" style="stop-color:#3273E2"/>
</linearGradient>
    <path class="st6" d="M37.5,60.5h25v1.9h-25V60.5z"/>
    <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="37.0499" y1="49.2356" x2="64.6279" y2="33.3136"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#F89200"/>
        <stop offset="1" style="stop-color:#A680D0"/>
</linearGradient>
    <path class="st7" d="M35.9,66.5l0.9-1.7l29,16.7l-1,1.7L35.9,66.5z"/>
    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="51.7178" y1="25.3543" x2="64.5398" y2="30.1183"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#00BEF2"/>
        <stop offset="1" style="stop-color:#A680D0"/>
</linearGradient>
    <path class="st8" d="M51.5,91l-0.7-1.8l14-5.1l0.6,1.8L51.5,91z"/>
    <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="85.716" y1="34.7539" x2="71.303" y2="32.0009"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#FF4C9F"/>
        <stop offset="1" style="stop-color:#A680D0"/>
</linearGradient>
    <path class="st9" d="M85.9,79.5l0.3,1.9l-15.1,2.9l-0.3-1.9L85.9,79.5z"/>
    <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="49.668" y1="92.823" x2="32.555" y2="86.237"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#5CD551"/>
        <stop offset="1" style="stop-color:#CE0003"/>
</linearGradient>
    <path class="st10" d="M49.9,21.3l0.7,1.8l-18.3,7.1l-0.7-1.8L49.9,21.3z"/>
    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="52.055" y1="81.4139" x2="52.845" y2="90.9379"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#AAAAAA"/>
        <stop offset="0.184" style="stop-color:#A2AEA1"/>
        <stop offset="0.484" style="stop-color:#8DBA89"/>
        <stop offset="0.861" style="stop-color:#6ACD61"/>
        <stop offset="1" style="stop-color:#5CD551"/>
</linearGradient>
    <path class="st11" d="M53,34.1l-1.9-0.2l0.8-9.8l1.9,0.2L53,34.1z"/>
    <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="84.1127" y1="80.3857" x2="56.5697" y2="92.6007"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#F8BF00"/>
        <stop offset="1" style="stop-color:#5CD551"/>
</linearGradient>
    <path class="st12" d="M85.1,34.3L84.4,36L55.6,23.3l0.7-1.8L85.1,34.3z"/>
    <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="84.2827" y1="77.8746" x2="56.5397" y2="76.5776"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#F8BF00"/>
        <stop offset="0.6" style="stop-color:#B6AD91"/>
        <stop offset="1" style="stop-color:#AAAAAA"/>
</linearGradient>
    <path class="st13" d="M84.3,36.4l0.1,2l-27.9,1.3l-0.1-2L84.3,36.4z"/>
    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="65.9191" y1="50.5965" x2="67.6411" y2="34.4275"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#3273E2"/>
        <stop offset="0.99" style="stop-color:#A680D0"/>
</linearGradient>
    <path class="st14" d="M64.9,64.6l2-0.2l1.7,16.5l-1.9,0.2L64.9,64.6z"/>
    <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="54.5663" y1="71.5399" x2="63.5383" y2="57.1039"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#AAAAAA"/>
        <stop offset="1" style="stop-color:#3273E2"/>
</linearGradient>
    <path class="st15" d="M53.3,43.6l1.7-1.1l9.8,15.8l-1.7,1.1L53.3,43.6z"/>
    <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="88.4131" y1="73.0412" x2="86.4881" y2="60.3172"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#F8BF00"/>
        <stop offset="1" style="stop-color:#00BC9B"/>
</linearGradient>
    <path class="st16" d="M87.5,41.8l1.9,0.3l-2,13.3l-1.9-0.3L87.5,41.8z"/>
    <linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="80.678" y1="54.867" x2="68.845" y2="54.039"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#00BC9B"/>
        <stop offset="0.99" style="stop-color:#3273E2"/>
</linearGradient>
    <path class="st17" d="M80.7,59.4l0.2,2l-12.1,0.8l-0.2-1.9L80.7,59.4z"/>
    <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="86.5375" y1="50.0982" x2="88.4645" y2="38.7412"
                    gradientTransform="matrix(1 0 0 -1 0 115.2756)">
	<stop offset="0" style="stop-color:#00BC9B"/>
        <stop offset="1" style="stop-color:#FF4C9F"/>
</linearGradient>
    <path class="st18" d="M85.5,65l1.9-0.3l2.1,12L87.6,77L85.5,65z"/>
    <path class="st19" d="M29.9,52.7c4.8,0.5,8.4,4.7,7.9,9.6c-0.5,4.8-4.7,8.4-9.6,7.9c-4.8-0.5-8.4-4.7-7.9-9.6
	C20.8,55.8,25.1,52.2,29.9,52.7z"/>
    <path class="st20"
          d="M86.2,54.8c2.9,0.3,5,2.8,4.8,5.7c-0.3,2.9-2.8,5-5.7,4.8c-2.9-0.3-5-2.8-4.8-5.7S83.3,54.6,86.2,54.8z"/>
    <path class="st21"
          d="M89.7,31.9c2.9,0.3,5,2.8,4.8,5.7c-0.3,2.9-2.8,5-5.7,4.8c-2.9-0.3-5-2.8-4.8-5.7S86.8,31.6,89.7,31.9z"/>
    <path class="st22"
          d="M53.4,17.6c1.9,0.2,3.4,1.9,3.2,3.8s-1.9,3.4-3.8,3.2c-1.9-0.2-3.4-1.9-3.2-3.8S51.5,17.5,53.4,17.6z"/>
    <path class="st23" d="M89.3,76.4c1.9,0.2,3.4,1.9,3.2,3.8c-0.2,1.9-1.9,3.4-3.8,3.2c-1.9-0.2-3.4-1.9-3.2-3.8
	C85.7,77.6,87.4,76.2,89.3,76.4z"/>
    <path class="st24" d="M41.4,92.7c-0.5-2.9,1.5-5.6,4.3-6.1c2.9-0.5,5.6,1.5,6.1,4.3c0.5,2.9-1.5,5.6-4.3,6.1
	C44.6,97.5,41.9,95.6,41.4,92.7z"/>
    <path class="st25" d="M32.4,31.6c-0.7,1.8-2.7,2.7-4.5,2.1C26,33,25.1,31,25.8,29.2c0.7-1.8,2.7-2.7,4.5-2.1
	C32.1,27.8,33,29.8,32.4,31.6z"/>
    <path class="st1" d="M53.8,43.7c-2.6,1.2-5.8,0-7-2.6s0-5.8,2.6-7s5.8,0,7,2.6C57.6,39.4,56.4,42.5,53.8,43.7z"/>
    <path class="st26" d="M64.4,64.7c-1.8-0.7-2.7-2.7-2.1-4.5c0.7-1.8,2.7-2.7,4.5-2.1c1.8,0.7,2.7,2.7,2.1,4.5
	C68.2,64.5,66.2,65.4,64.4,64.7z"/>
    <path class="st27" d="M65.3,86.1c-1.2-1.5-1-3.7,0.5-4.9c1.5-1.2,3.7-1,4.9,0.5c1.2,1.5,1,3.7-0.5,4.9
	C68.7,87.8,66.5,87.6,65.3,86.1z"/>
</svg>
