import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoinomiSdkService} from './coinomi-sdk.service';
import {CoinomiModule} from '../coinomi/coinomi.module';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatBottomSheetModule,
        MatSnackBarModule,
        CoinomiModule
    ],
    providers: [
        CoinomiSdkService
    ]
})
export class CoinomiSdkModule {
    constructor(@Optional() @SkipSelf() parentModule: CoinomiSdkModule) {
        if (parentModule) {
            throw new Error(
                'CoinomiSdkModule is already loaded. Import it in the AppModule only');
        }
    }
}
