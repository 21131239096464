import {Component, OnInit} from '@angular/core';

import {CoinomiSdkService} from '../../coinomi-sdk/coinomi-sdk.service';

@Component({
    selector   : 'intro',
    templateUrl: './intro.component.html',
})
export class IntroComponent implements OnInit {

    constructor(private coinomiSdkService: CoinomiSdkService) {

    }

    ngOnInit(): void {
        this.coinomiSdkService.setViewSettings('Intro', false);

    }
}
