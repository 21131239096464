<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
    <p>{{data.message}}</p>
    <input matInput [(ngModel)]="data.inputValue" [placeholder]="data.inputValueTitle" *ngIf="enableInput"/>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close *ngIf="!disableCancel">Cancel</button>
    <button mat-button color="accent" [mat-dialog-close]="data.inputValue" cdkFocusInitial *ngIf="enableInput">OK</button>
    <button mat-button color="accent" [mat-dialog-close]="true" cdkFocusInitial  *ngIf="!enableInput">OK</button>

</mat-dialog-actions>