import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CoinAccount, CoinomiSdkService, CoinSettings, CoinType } from '../../coinomi-sdk/coinomi-sdk.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThrowStmt } from '@angular/compiler';

@Component({
    selector: 'cnm-select-account',
    templateUrl: './select-account.component.html',
    styleUrls: ['./select-account.component.scss']
})
export class CnmSelectAccountComponent implements OnInit, OnChanges {
    @Input('address')
    address: string;
    @Output() addressChange: EventEmitter<string> = new EventEmitter<string>();

    @Input('account-title')
    accountTitle: string;

    @Input('address-title')
    addressTitle: string;

    @Input('account')
    account: CoinAccount;
    @Output() accountChange: EventEmitter<CoinAccount> = new EventEmitter<CoinAccount>();

    @Input('coin-types')
    coinTypes: string[];

    @Input('coin-symbols')
    coinSymbols: string[];

    @Input('coin-id')
    coinId: string;

    @Input('add-coin-settings')
    addCoinSettings: CoinSettings;

    @Input('account-help-text')
    accountHelpText: string;

    @Input('address-help-text')
    addressHelpText: string;

    @Input('allow-empty-account')
    allowEmptyAccount = false;

    ethAccounts: string[] = ['USDT', 'USDC'];

    coinAccounts: CoinAccount[];

    constructor(private coinomiSdkService: CoinomiSdkService,
        private snackBar: MatSnackBar) {

    }

    ngOnInit(): void {

    }

    onAddressChange(newValue): void {
        // console.log('onAddressChange', typeof newValue,  newValue);
        this.address = newValue;
        this.addressChange.emit(newValue);
    }

    onAccountChange(newValue): void {
        // console.log('onAccountChange', typeof newValue,  newValue);
        this.onAddressChange(this.account && this.account.address || '');
        this.accountChange.emit(this.account);
    }

    addCoin(): void {
        if (this.coinomiSdkService.isCoinomi() && this.addCoinSettings) {
            // TODO improve coin symbols

            let coinTypes = [
                {

                    id: this.coinId,
                    name: this.coinSymbols[0],
                    symbol: this.coinSymbols[0]
                }
            ];
            if (coinTypes != null && coinTypes.length > 0) {
                this.addCoinSettings.id = coinTypes[0].id;
            }

            let i = this.ethAccounts.findIndex(e => e == this.addCoinSettings.symbol);
            //If coins are ETH coin types then create ETH acoount first then the ethCoin accounts.
            if (i >= 0) {
                // let ethCoinTypes = this.coinomiSdkService.coinTypesMapping.filter(e => e.symbol == 'ETH');
                let ethCoinTypes = [
                    {
                        id: 'ethereum.main',
                        name: 'Ethereum',
                        symbol: 'ETH'
                    }
                ];

                this.coinomiSdkService.getCoinAccountsForCoinTypes(ethCoinTypes).then(coinAccounts => {
                    //ETH wallet does exist, just create account with addCoinSettings
                    if (coinAccounts != null && coinAccounts.length > 0) {
                        this.coinomiSdkService.addCoin(this.addCoinSettings).then(coinAccount => {
                            this.account = coinAccount;
                            this.updateAccounts();
                        }, err => {
                            //TODO: on adding USDT or USDC account response comes back as User Cancelled even though account is created, needed to inestigate. 
                            //Until resolution solution below
                            this.updateAccounts();
                            if (err.code) { // has error code, means is not just a cancel
                                this.snackBar.open(err.message, null, {
                                    duration: 5000
                                });
                            }

                        });
                    } else {
                        let coinSettings = { symbol: "ETH" };
                        this.coinomiSdkService.addCoin(coinSettings).then(coinAccount => {
                            this.updateAccounts();
                        }, err => {
                            if (err.code) { // has error code, means is not just a cancel
                                this.snackBar.open(err.message, null, {
                                    duration: 5000
                                });
                            }
                        });
                    }
                });
            } else {
                this.coinomiSdkService.addCoin(this.addCoinSettings).then(coinAccount => {
                    this.account = coinAccount;
                    this.updateAccounts();
                }, err => {
                    this.updateAccounts();
                    if (err.code) { // has error code, means is not just a cancel
                        this.snackBar.open(err.message, null, {
                            duration: 5000
                        });
                    }

                });
            }
        }
    }

    updateAccounts(): void {
        if (this.coinomiSdkService.isCoinomi()) {
            let promise: Promise<CoinAccount[]>;

            let coinTypes = [
                {

                    id: this.coinId,
                    name: this.coinSymbols[0],
                    symbol: this.coinSymbols[0]
                }
            ];
            if (coinTypes != null && coinTypes.length > 0) {
                promise = this.coinomiSdkService.getCoinAccountsForCoinTypes(coinTypes);
            } else if (this.coinSymbols) {
                promise = this.coinomiSdkService.getCoinAccountsForCoinSymbols(this.coinSymbols);
            } else {
                promise = this.coinomiSdkService.getCoinAccounts();
            }

            promise.then(coinAccounts => {
                this.coinAccounts = coinAccounts;
                this.account = this.account && coinAccounts.find(account => account.id === this.account.id) || (!this.allowEmptyAccount && (this.coinAccounts.length ? this.coinAccounts[0] : null));
                this.onAccountChange(this.account);
            }, err => {
                console.log(err);
            });
            this.setCoinSymbol();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'coinSymbols':
                    case 'coinTypes': {
                        this.updateAccounts();
                    }
                }
            }
        }
    }




    coinSymbol: string = "";
    setCoinSymbol() {
        if (this.addCoinSettings) {
            let i = this.ethAccounts.findIndex(e => e == this.addCoinSettings.symbol);
            //If coins are ETH coin types then create ETH acoount first then the ethCoin accounts.
            if (i >= 0) {

                let ethCoinTypes = [
                    {
                        id: 'ethereum.main',
                        name: 'Ethereum',
                        symbol: 'ETH'
                    }
                ];
                this.coinomiSdkService.getCoinAccountsForCoinTypes(ethCoinTypes).then(coinAccounts => {
                    if (coinAccounts != null && coinAccounts.length > 0) {
                        this.coinSymbol = this.addCoinSettings.symbol;
                    } else {
                        this.coinSymbol = "ETH";
                    }
                });

            } else {
                this.coinSymbol = this.addCoinSettings.symbol;
            }
        }

    }
}
