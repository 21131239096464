import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'cnm-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CnmIconComponent implements OnInit {

    @Input('icon')
    icon: 'logo' | 'brand' | 'logo-brand';

    @Input('color')
    color: 'white' | 'black' = 'white';

    constructor() {
    }


    ngOnInit(): void{
    }

}
