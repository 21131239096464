import { FuseNavigation } from '@fuse/types';
import {environment} from '../../environments/environment';

const isDevelop = environment.production;

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        type     : 'group',
        hidden   : true,
        children : [
            {
                id       : 'sample',
                title    : 'Sample',
                type     : 'item',
                icon     : 'email',
                url      : '/sample',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                },
                hidden   : isDevelop,
            },
            {
                id       : 'sample-menu',
                title    : 'Sample Menu',
                type     : 'item',
                icon     : 'email',
                url      : '/sample-menu',
                hidden   : isDevelop,
            }
        ]
    },
    {
        id       : 'services',
        title    : 'Services',
        type     : 'group',
        hidden   : false,
        children : [
            {
                id       : 'splitter',
                title    : 'BitcoinCash Splitter',
                type     : 'item',
                icon     : 'call_split',
                url      : '/splitter',
                hidden   : isDevelop,
            },
            {
                id       : 'btc-direct',
                title    : 'BTC Direct',
                type     : 'item',
                icon     : 'call_split',
                url      : '/btc-direct',
                hidden   : false,
            },
            {
                id       : 'simplex',
                title    : 'Simplex',
                type     : 'item',
                icon     : 'call_split',
                url      : '/simplex',
                hidden   : false,
            },
            {
                id       : 'fio',
                title    : 'FIO',
                type     : 'item',
                icon     : 'call_split',
                url      : '/fio',
                hidden   : false,
            },
        ]
    }
];
