import {NgModule, Optional, SkipSelf} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/sample/sample.module';
import {CoinomiSdkModule} from './coinomi-sdk/coinomi-sdk.module';
import {CoinomiModule} from './coinomi/coinomi.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {QRCodeModule} from 'angularx-qrcode';
import {IntroComponent} from './main/intro/intro.component';
import {MatMenuModule} from '@angular/material/menu';


const appRoutes: Routes = [
    {
        path: '',
        component: IntroComponent,
    },
    // {
    //     path: '',
    //     redirectTo: 'btc-direct',
    //     pathMatch: 'full'
    // },
    {
        path: 'splitter',
        loadChildren: () => import('./main/splitter/splitter.module').then(m => m.SplitterModule)
    },
    {
        path: 'btc-direct',
        loadChildren: () => import('./main/btc-direct/btc-direct.module').then(m => m.BtcDirectModule)
    },
    {
        path: 'simplex',
        loadChildren: () => import('./main/simplex/simplex.module').then(m => m.SimplexModule)
    },
    {
        path: 'fio',
        loadChildren: () => import('./main/fio/fio.module').then(m => m.FioModule)
    },
    {   // this always last
        path: '**',
        redirectTo: 'btc-direct',
    },
];

@NgModule({
    declarations: [
        AppComponent,
        IntroComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatMenuModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        CoinomiModule,

        // Coinomi SDK
        CoinomiSdkModule,

        // App modules
        LayoutModule,
        SampleModule,
        MatProgressSpinnerModule,
        QRCodeModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
