import {Component, Inject, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'cnm-dialog',
    templateUrl: './cnm-dialog.component.html',
})
export class CnmDialogComponent {
    public disableCancel = false;
    public enableInput = false;
    inputValue: string;

    form: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: CnmDialogData) {
        if (data.options){
            this.disableCancel = data.options.disableCancel;
            this.enableInput = data.options.enableInput;
        }
    }
}

export interface CnmDialogData {
    title: string;
    message: string;
    inputValue?: string;
    inputValueTitle?:string;
    options?: {
        disableCancel?: boolean;
        enableInput?: boolean;
    };
}
