import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { SampleComponent } from './sample.component';
import {SampleMenuComponent} from './sample-menu.component';
import {FuseDemoModule, FuseNavigationModule, FuseSidebarModule} from '../../../@fuse/components';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

const routes = [
    {
        path     : 'sample',
        component: SampleComponent
    },
    {
        path     : 'sample-menu',
        component: SampleMenuComponent
    }
];

@NgModule({
    declarations: [
        SampleComponent,
        SampleMenuComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        FuseDemoModule,
        FuseSidebarModule,
        FuseNavigationModule,
        MatIconModule,
        MatButtonModule
    ],
    exports     : [
        SampleComponent
    ]
})

export class SampleModule
{
}
