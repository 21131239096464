<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign="space-between center" fxFlex>

        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="font-size-12 coinomi-brand">© 2021 <span class="coinomi font-size-14">coinomi</span></span>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" class="links">
            <a href="https://www.coinomi.com/en/terms/" target="_blank">Terms of use</a>
            &nbsp;<span class="font-size-8">|</span>&nbsp;
            <a href="https://www.coinomi.com/en/privacy/" target="_blank">Privacy policy</a>
        </div>

    </div>

</mat-toolbar>
